import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
} from '@angular/router';
import { AzureAdConfigService } from '../services/azure-ad-config.service';

/* If the user is not authenticated, they are redirected to the login page */
@Injectable()
export class AuthGuard {
  constructor(
    private router: Router,
    private azureAdConfigService: AzureAdConfigService,
  ) { }

  /**
   * This function is called when the user navigates to a route that requires authentication.
   * If the user is not authenticated, they are redirected to the login page
   * @returns A boolean value.
   */
  async canActivate(): Promise<boolean> {
    const validLogin = await this.azureAdConfigService.isAuthenticatedCheck();
    if (!validLogin) {
      this.azureAdConfigService.logout();
    }
    return true;
  }
}
