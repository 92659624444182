import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-under-maintenance-modal',
  templateUrl: './site-under-maintenance-modal.component.html',
  styleUrls: ['./site-under-maintenance-modal.component.scss']
})
export class SiteUnderMaintenanceModalComponent implements OnInit {

  ngOnInit(): void {

  }

}
