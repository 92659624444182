import { Injectable } from '@angular/core';
import {
  filter, map, Observable, ReplaySubject,
} from 'rxjs';

interface BroadcastObject {
  key: string,
  value: any,
}

/**
 *
 *
 * @export
 * @class StorageService
 */
@Injectable()
export class StorageService {
  /**
   *
   *
   * @private
   * @memberof StorageService
   */
  private datamap = new Map();

  /**
   *
   *
   * @private
   * @type {ReplaySubject<BroadcastObject>}
   * @memberof StorageService
   */
  private eventQueue: ReplaySubject<BroadcastObject>;

  /**
   * Creates an instance of StorageService.
   * @memberof StorageService
   */
  constructor() {
    this.eventQueue = new ReplaySubject<BroadcastObject>();
  }

  /**
   *
   *
   * @param {string} key
   * @param {*} value
   * @memberof StorageService
   */
  public set(key: string, value: any): void {
    this.datamap.set(key, value);
    this.broadcast(key, value);
  }

  /**
   *
   *
   * @param {string} key
   * @return {*}  {*}
   * @memberof StorageService
   */
  public get(key: string): any {
    if (this.checkData(key) !== undefined) {
      return this.datamap.get(key);
    }
    throw new Error('Cannot get a non-existent value');
  }

  /**
   * Delete a key from the map
   * @param {string} key - The key of the item to delete.
   */
  public delete(key: string): void {
    this.datamap.delete(key);
  }

  /**
   * If the key exists, broadcast the value
   * @param {string} key - The key to broadcast.
   * @param {any} [value] - The value to broadcast.
   * @returns Nothing.
   */
  public broadcastValue(key: string, value?: any): boolean {
    if (this.checkData(key) || value !== undefined) {
      this.broadcast(key, value);
      return true;
    }
    throw new Error('Cannot broadcast a non-existent value');
  }

  /**
   * Broadcast a value to all subscribers of a given key
   * @param {string} key - The key of the value to broadcast.
   * @param {any} [value] - The value to broadcast.
   */
  public broadcast(key: string, value?: any): void {
    const newValue = value || this.get(key);
    this.eventQueue.next({ key, value: newValue });
  }

  /**
   * Listen to a specific key and return the value of that key
   * @param {string} key - The key to listen to.
   * @returns An observable that emits the value of the key that was passed in.
   */
  public listen(key: string): Observable<any> {
    const observable = this.eventQueue.asObservable().pipe(
      filter((data: BroadcastObject) => data.key === key),
      map((data: BroadcastObject) => data.value),
    );
    return observable;
  }

  /**
   * Listen to multiple events at once
   * @param {string[]} keys - A string array of keys to listen to.
   * @returns An observable that emits the values of the keys that were passed in.
   */
  public listenMany(keys: string[]): Observable<any> {
    const observable = this.eventQueue.asObservable().pipe(
      filter((data: BroadcastObject) => keys.includes(data.key)),
      map((data: BroadcastObject) => data.value),
    );
    return observable;
  }

  /**
   * Unsubscribe() is called when the subscription is no longer needed
   */
  public unsubscribe(): void {
    this.eventQueue.complete();
  }

  /**
   * Check if the given key exists in the datamap.
   * @param {string} key - The key to check.
   * @returns The boolean value of whether or not the key exists in the data map.
   */
  public checkData(key: string): boolean {
    return this.datamap.has(key);
  }

  /**
   * Clear the data in the datamap for the given keys
   * @param {string[]} keys - An array of keys to clear.
   */
  public clearData(keys: string[]): void {
    keys.forEach((key) => {
      this.datamap.delete(key);
    });
  }
}
