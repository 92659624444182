export const environment = {
  envName: 'dev',
  production: false,
  config: {
    REPORT_SERVICE_URI: 'https://dev.cookr.in/api/report-analytics-service/v1/reports',
    ADMIN_SERVICE_URI: 'https://dev.cookr.in/api/admin-operations-service/v2',
    CONTROL_TOWER: 'https://controltower.dev.cookr.in/',
    ADMIN_PANEL: 'https://admin.dev.cookr.in/',
    SELLER_PIPELINE: 'https://sellerpipeline.dev.cookr.in/',
    azureAd: {
      CLIENT_ID: 'c798b78d-825c-4f56-83aa-930652c8ab0e',
      AUTHORITY: 'https://login.microsoftonline.com/40a4ede4-f920-4fc1-968a-d08f490515be',
      REDIRECT_URI: '/',
      POST_LOGOUT_REDIRECT_URI: '/',
      API_SCOPE: 'api://2bf21aca-2837-404c-899b-02e1a490f8dd/api.scopes',
      USER_PROFILE_GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
    },
  },
};
