<div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-body">
      <div class="site-under-maintenance">
        <img src="../../../../assets/Websitemaintenance.svg">
        <h5 class="title">Site Under Maintenance</h5>
        <div class="description">
          <p>Our site is currently undergoing maintenance and updates, and will return shortly.</p>
          <span>Thank you for your patience</span>
        </div>
      </div>
    </div>
  </div>
</div>