import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { InsertionDirective } from './insertion.directive';
import { ModalService } from './modal.service';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ModalComponent,
    InsertionDirective,
  ],
  providers: [ModalService],
  entryComponents: [ModalComponent],
})
export class ModalModule {}
