import { InitialService } from './../services/initial.service';
import { AuthGuard } from '../guards/auth.guard';
import { AbilityGuard } from '../guards/ability.guard';
import { AbilityModule } from '@casl/angular';
import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MsalService, MSAL_INSTANCE, MsalInterceptor, MSAL_INTERCEPTOR_CONFIG, MsalModule } from '@azure/msal-angular';
import { HttpClientInterceptor } from '../interceptors/http.interceptor';
import { AppAbility } from '../services/appAbility';
import { AzureAdConfigService, MSALInstanceFactory, MSALInterceptorConfigFactory } from '../services/azure-ad-config.service';
import { PureAbility } from '@casl/ability';
import { StorageService } from '../services/storage.service';
import { HotToastModule } from '@ngneat/hot-toast';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SiteUnderMaintenanceModalComponent } from './shared/site-under-maintenance-modal/site-under-maintenance-modal.component';
import { ModalModule } from '../lib/modal/modal.module';
export function initAuth(AzureAdConfigService: AzureAdConfigService) {
  return () => AzureAdConfigService.loadAppConfigurations();
}

@NgModule({
  declarations: [
    AppComponent,
    SiteUnderMaintenanceModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoadingBarModule,
    HotToastModule.forRoot({
      position: 'top-center',
      className: 'hot-toast',
      duration: 2000,
    }),
    AbilityModule,
    MsalModule,
    ModalModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    AuthGuard,
    AbilityGuard,
    MsalService,
    AzureAdConfigService,
    StorageService,
    InitialService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAuth,
      deps: [AzureAdConfigService],
      multi: true,
    },
    { provide: AppAbility, useValue: AppAbility() },
    { provide: PureAbility, useExisting: AppAbility },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
