import {
  createMongoAbility, AbilityBuilder, InferSubjects, MongoAbility, CreateAbility, ForbiddenError,
} from '@casl/ability';
import { IAbility } from './api/user-api.service';

  type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete' | 'view';
  type Subjects = InferSubjects<any> | 'all';
  type AppAbilities = [Actions, Subjects];
export type AppAbility = MongoAbility<AppAbilities>;
export const AppAbility = createMongoAbility as CreateAbility<AppAbility>;
ForbiddenError.setDefaultMessage((error) => `You are not allowed to ${error.action} on ${error.subject}`);
export function defineAbilities(abilities: IAbility[]) {
  const { can, cannot, build } = new AbilityBuilder<AppAbility>(createMongoAbility);
  if (Array.isArray(abilities) && abilities.length) {
    abilities.forEach((entity: IAbility) => {
      entity.actions.forEach((action: any) => {
        can(action, entity.subjectValue);
      });
    });
  }
  return build();
}
