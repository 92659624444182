import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { HttpMethod } from '../enums/httpmethod.enum';
import { environment } from '../environments/environment';

export interface IHttpClientAdditionalClientOptions {
  preferLatestCall?: boolean,
  responseType?: string,
}

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  inflightPromiseMap = new Map<string, Subscription>();

  /**
   * Creates an instance of HttpClientService.
   * @param {HttpClient} http
   * @memberof HttpClientService
   */
  constructor(
    private http: HttpClient,
    private msalService: MsalService,
  ) { }

  /**
   * It calls the API and returns the response.
   * @param {string} path - The path to the API endpoint.
   * @param {HttpMethod} method - HttpMethod.GET,
   * @param {any} payload - The data that you want to send to the server.
   * @param [isAuthenticatedRoute=true] - If the route requires authentication, set this to true.
   * @param additionalHeaders - any
   * @returns A promise.
   */
  async call(
    path: string,
    payload?: any,
    isAuthenticatedRoute = true,
    method: HttpMethod = HttpMethod.GET,
    additionalHeaders = {},
    additionalOptions: IHttpClientAdditionalClientOptions = {
      preferLatestCall: false,
      responseType: 'json',
    },
    // otherResponseOptions: IOtherResponseOption = {
    //   responseType: 'json',
    // }
  ) {
    const url = `${path}`; // TODO: prepend base api url
    const headers: any = {
      'Content-Type': 'application/json',
    };
    if (isAuthenticatedRoute) {
      const activeAccount = this.msalService.instance.getAllAccounts()[0];
      const request = {
        account: activeAccount,
        scopes: [environment.config.azureAd.API_SCOPE],
      };
      const token = await firstValueFrom(this.msalService.acquireTokenSilent(request));
      if (token) {
        headers['accesstoken'] = token.accessToken;
        headers['idtoken'] = token.accessToken;
      }
    }
    const allHeaders = {
      ...headers,
      ...additionalHeaders,
      'user-current-view': 'admin',
    };
    const httpHeaders = new HttpHeaders(allHeaders);
    const options = {
      headers: httpHeaders,
      body: payload,
      responseType: additionalOptions?.responseType || 'json' as any,
    };
    if (additionalOptions.preferLatestCall) {
      const promiseIdentifier = url.split(/[?#]/)[0];

      return new Promise((resolve, reject) => {
        const subscription = this.http.request(method, url, options).subscribe((res) => {
          this.inflightPromiseMap.delete(promiseIdentifier);
          resolve(res);
        });
        const inflightController = this.inflightPromiseMap.get(promiseIdentifier);
        if (inflightController) {
          inflightController.unsubscribe();
          this.inflightPromiseMap.delete(promiseIdentifier);
        }
        this.inflightPromiseMap.set(promiseIdentifier, subscription);
      });
    }
    const reponse = await firstValueFrom(this.http.request(method, url, options));
    return reponse;
  }
}
