import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  Type,
  EmbeddedViewRef,
  ComponentRef,
} from '@angular/core';
import { ModalComponent } from './modal.component';
import { ModalInjector } from './modal-injector';
import { ModalConfig } from './modal-config';
import { ModalRef } from './modal-ref';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  modalComponentRef!: ComponentRef<ModalComponent>;

  private openModals: any[] = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) { }

  public open(componentType: Type<any>, config?: ModalConfig) {
    const dialogRef = this.appendDialogComponentToBody(config);
    this.modalComponentRef.instance.childComponentType = componentType;
    return dialogRef;
  }

  private appendDialogComponentToBody(config?: ModalConfig) {
    const map = new WeakMap();
    map.set(ModalConfig, config);
    const dialogRef = new ModalRef();
    map.set(ModalRef, dialogRef);

    const sub = dialogRef.closeEmitter.subscribe(() => {
      this.removeDialogComponentFromBody();
      sub.unsubscribe();
    });

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalComponent);
    const componentRef = componentFactory.create(new ModalInjector(this.injector, map));

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.modalComponentRef = componentRef;

    this.modalComponentRef.instance.onClose.subscribe(() => {
      this.removeDialogComponentFromBody();
    });

    return dialogRef;
  }

  private removeDialogComponentFromBody() {
    this.appRef.detachView(this.modalComponentRef.hostView);
    this.modalComponentRef.destroy();
  }

  public closeAllModals() {
    this.openModals.forEach((modal) => modal.dismiss()); // Close each modal
    this.openModals = [];
    this.appRef.tick();
  }
}
